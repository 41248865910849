import React, { useState, useContext, useEffect, useRef } from 'react';
import {Row, Button, Col, Card, CardBody, FormGroup, Form, Label, Input, UncontrolledTooltip, InputGroup, InputGroupAddon, CustomInput, Spinner } from 'reactstrap';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentSave from '@iconify/icons-mdi/content-save';
import contentCopy from '@iconify/icons-mdi/content-copy';
import dragIcon from '@iconify/icons-mdi/drag';
import { OmniApi } from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import axios from 'axios';
import PageLock from '../utility-components/PageLock';
import { Helmet } from 'react-helmet';
import ImageUpload from './ImageUpload';
import { confirm } from './Confirm';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import circleSlice8 from '@iconify-icons/mdi/circle-slice-8';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import deleteIcon from '@iconify/icons-mdi/delete';
import arrowDownBold from '@iconify/icons-mdi/arrow-down-bold';
import closeIcon from '@iconify/icons-mdi/close';
import informationOutline from '@iconify/icons-mdi/information-outline';
import { CommonApiRequest } from '../services/commonApiRequest';
import { FS1STATICURL, FS1URL } from '../constants';
import { commonHelper } from '../helpers/commonHelper';
import { CSVExport } from 'react-bootstrap-table2-toolkit';

const Collection = props => {

    const [collection, setCollection] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    const { ExportCSVButton } = CSVExport;
    const [csvLoading, setCsvLoading] = useState(false);
    const today = new Date().toLocaleDateString();

    const getCsv = () => {

        function downloadCsv(blob, fileName) {
            var linkElement = document.createElement('a');
    
            var url = window.URL.createObjectURL(blob);
    
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", fileName);
    
            var clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);
        }

        setCsvLoading(true)

        OmniApi.get('/sites')
        .then(({data}) => {
           
            let queryParams = new URLSearchParams({
                site: data[0].siteToken,
                collectionToken: props.match.params.collectionToken,
                format: 'csv_for_collection_product',
                linktypes: 'products'
            });
            
            OmniApi.get( `/sitemap/download?${queryParams}`, {
                responseType: 'blob',
                
            })
            .then(({data}) => {
                downloadCsv(data, `${generateSlug(collection.collectionName)}_products_${today}.csv`)
            })
        })
        .catch(err => console.error(err))
        .finally(() => setCsvLoading(false))
    }
    useEffect(() => {
        let collectionToken = props.match.params.collectionToken;
        if (collectionToken){
            OmniApi.get(`/collections/${collectionToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Collection Token')
            
                    setCollection(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/catalog/collections')
                toast.error(err.message)
            })
        } else {
            setCollection({
                collectionName: '',
                collectionSlug: '',
                collectionImage: '',
                collectionDescription: '',
                collectionDefaultSort: '',
                collectionToken: '',
                products:[],
                hideCollection:false
            })
        }
    }, [props.location])

    const { user } = useContext(UserContext);

    const [slugDisabled, setSlugDisabled] = useState(true);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const generateSlug = (string) => string?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

    const uploadImage = (img) => {
        let payload = new FormData();

        payload.append('ev_file', img);

        axios.post(FS1STATICURL+'upload', payload, {
            headers: {
                'upload_token': user.company.fs1Token
              }
        })
        .then(res => setCollection({...collection, collectionImage: res.data.path}))
    }

    const handleImageUpload = (e) => {
        setUnsavedChanges(true)
        console.log('chnage1')
        const file = e.target.files[0];
        if(file){
            uploadImage(file);
        }
        else return
    }

    const deleteImage = async () => {
        if (await confirm("Delete this collections' image?", "Deleting Image", "Delete")){
            setUnsavedChanges(true)
            console.log('chnage2')
            setCollection({
                ...collection,
                collectionImage: ''
            });
        }
        else return
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)
        console.log('chnage6')

        if (name == 'collectionName'){
            setNameRequired(false);
        }

        if (name === 'collectionSlug'){
            return setCollection({
                ...collection,
                [name]: generateSlug(e.target.value)
            })
        }

        setCollection({
            ...collection,
            [name]: e.target.value
        })
    }

    const [nameRequired, setNameRequired] = useState(false);

    const saveCollection  = () => {

        let newCollection = { ...collection }
        if (!newCollection.collectionName)
            return setNameRequired(true)

        if (!newCollection.collectionSlug)
            newCollection.collectionSlug = generateSlug(newCollection.collectionName)

        newCollection.products.forEach((product, i) => product.sortOrder = i);

        if(!newCollection.collectionToken)
            delete newCollection.collectionToken;

        let postCollection = OmniApi.post(`/collections${collection.collectionToken ? '/' + collection.collectionToken : ''}`, newCollection)
        .then(res => {
            setUnsavedChanges(false)
            props.history.replace(`${props.location.pathname}${collection.collectionToken ? '' : '/' + res.data.collectionToken}`)})

        toast.promise(
            postCollection,
            {
                pending: {
                    render() {
                        return "Saving collection..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Collection saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        console.log(err)
                        return `${err.data}`
                    },
                    icon: true,
                }
            }
        )

    }

    const deleteCollection = async () => {

        if (await confirm(`Delete '${collection.collectionName}' from collections?`, "Deleting Collection...", "Delete") === false) {
            return
        }

        let deleteColl = OmniApi.delete(`/collections/${collection.collectionToken}`)
        .then(res => props.history.replace('/omni/catalog/collections'))
        .catch(err => console.error(err))

        toast.promise(
            deleteColl,
            {
                pending: {
                    render() {
                        return "Deleting collection..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `'${collection.collectionName}' deleted from collections`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const [productList, setProductList] = useState([])

    useEffect(() => {
        OmniApi.get('/products')
        .then(res => setProductList(res.data))
        .catch(err => {})
    }, [])

    const productsTypeaheadRef = useRef(null);

    // add product to collection
    const onProductSelectChange = (select) => {
        const dataCheck = collection.products.find(item=>item?.prodToken===select?.[0]?.prodToken);
        if(!dataCheck){
            //setUnsavedChanges(true)
            if(collection?.collectionToken){
                let productSavingToast = toast.loading('Adding product...', {
                    theme: 'light',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                CommonApiRequest.saveSingleProductInCollection({prodToken:select?.[0]?.prodToken,collectionToken:collection?.collectionToken,sortOrder:collection?.products.length}).then((response)=>{
                    if(response?.added){
                        toast.update(productSavingToast,
                        {
                            render: "Product added successfully!",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                        setCollection({
                            ...collection,
                            products:[
                                ...collection.products,
                                ...select
                            ]
                        });
                        setTimeout(() => {
                            productsTypeaheadRef.current.clear();
                        }, 250);
                    }
                });
            } else {
                setCollection({
                    ...collection,
                    products:[
                        ...collection.products,
                        ...select
                    ]
                });
                setTimeout(() => {
                    productsTypeaheadRef.current.clear();
                }, 250);
            }
        } else {
            toast.error('Product already exist in collection');
            setTimeout(() => {
                productsTypeaheadRef.current.clear()
            }, 250)
        }
        
    }

    const removeCollectionProduct = (prodToken) => {
        //setUnsavedChanges(true)
        if(collection?.collectionToken){
            let productDeleteToast = toast.loading('Removing product...', {
                theme: 'light',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            CommonApiRequest.deleteSingleProductInCollection(collection?.collectionToken,prodToken).then((response)=>{
                if(response?.deleted){
                    toast.update(productDeleteToast,
                        {
                            render: "Product removed successfully!",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    setCollection({
                        ...collection,
                        products: collection.products.filter(product => product.prodToken !== prodToken)
                    })
                }
            });
        } else {
            setCollection({
                ...collection,
                products: collection.products.filter(product => product.prodToken !== prodToken)
            })
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(collection[destination.droppableId], source.index, destination.index);

            setCollection({
                ...collection,
                [destination.droppableId]: items
            })

        } else {
            return
        }
    }

    const getFS1Image = (img, size) => {
        return commonHelper.returnFs1ImagesUrl(`${user.company.fs1GetToken}/${img}/${size}`);
    }

    const truncateString = (string, length) => {
        let slicedString = string.slice(0, length)
        if (string.length > length)
        slicedString = slicedString.concat('...')
        return slicedString
    }

    if (collection && user)
        return(
            <>
            <PageLock unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Collection Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    {collection.collectionToken ? (
                    <h2>{collection.collectionName}</h2>
                    ) : (
                    <h2>Create collection</h2>
                    )}
                </div>
                <div>
                   {/*  <ExportCSVButton className="export-csv-btn btn btn-primary mt-2">
                    Export CSV
                    </ExportCSVButton> */}
                     <Button className="export-csv-btn mt-2 mb-0" color="primary" onClick={getCsv}
                        disabled={csvLoading}
                        >
                            {(!csvLoading) ? 'Download Collection' : <Spinner size="sm"/>}
                        </Button>
                </div>
            </div>
            <Row>
                <Col sm={9}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label htmlFor="collectionName">Collection Name</Label>
                                        <Input type="text" name="collectionName" id="collectionName"
                                        value={collection.collectionName}
                                        onChange={handleInputChange}
                                        className={`${nameRequired ? 'invalid' : ''}`}
                                        placeholder="Collection name..."
                                        />
                                        {nameRequired ? <div className="invalid-text">Collection name is required</div> : ''}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="collectionSlug">Collection Handle</Label>
                                        <InputGroup>
                                            <Input type="text" name="collectionSlug" id="collectionSlug"
                                                value={collection.collectionSlug}
                                                onChange={handleInputChange}
                                                placeholder="Collection handle..."
                                                disabled={slugDisabled} />
                                            <InputGroupAddon addonType="append">
                                                <span
                                                    className="btn btn-secondary input-group-text py-0 px-1"
                                                    onClick={() => setSlugDisabled(!slugDisabled)}
                                                >
                                                    <Icon icon={slugDisabled ? pencilIcon : contentSave} width="25" height="25" />
                                                </span>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append">
                                                <span
                                                    className="btn btn-secondary input-group-text py-0 px-1"
                                                    onClick={
                                                        // this is necessary when there isn't a productSlug
                                                        () => copyToClipboard(collection.collectionSlug
                                                            ||
                                                            generateSlug(collection.collectionName))}
                                                >
                                                    <Icon icon={contentCopy} width="25" height="25" id="copy-slug" />
                                                </span>
                                                <UncontrolledTooltip placement="top" target="copy-slug" trigger="legacy">
                                                    {collection.collectionSlug} copied to clipboard
                                                </UncontrolledTooltip>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <p className="mt-1 mb-0">
                                            <strong>Collection Handle:</strong>{` "/omni/catalog/collection/${collection.collectionSlug || '{collection_slug}'}"`}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="collectionSlug">Hide this collection</Label>
                                        <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="collection-setting" />
                                        <UncontrolledTooltip placement="top" target="collection-setting">
                                            This will hide collection from store.
                                        </UncontrolledTooltip>
                                        <CustomInput
                                            type="switch"
                                            id="exampleCustomSwitch"
                                            name="customSwitch"
                                            checked={collection?.hideCollection}
                                            onChange={(e) => {
                                                setCollection({
                                                    ...collection,
                                                    hideCollection:e?.target?.checked
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Collection Description</Label>
                                        <Input type="textarea" name="collectionDescription" id="collectionDescription"
                                        rows="6"
                                        value={collection.collectionDescription}
                                        onChange={handleInputChange}
                                        placeholder="Description..."
                                         />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card>
                        <CardBody className="d-flex justify-content-center align-items-center">
                            <ImageUpload 
                            handleImageUpload={handleImageUpload} 
                            image={collection.collectionImage} 
                            deleteImage={deleteImage}
                            allowDelete={true}
                            imageSize={'200'}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md='10' lg='8' xl="6">
                <Card>
                    <CardBody>
                        <Row>
                            <Col className="d-flex justify-content-between">
                            <h3>Collection Products</h3>
                            <div>Total: <strong>{collection.products?.length}</strong></div> 
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10} lg={8}>
                                <InputGroup  className="mb-2">
                                    <Typeahead
                                    ref={productsTypeaheadRef}
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="prodName"
                                    multiple={false}
                                    options={productList.map(product => product)}
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onProductSelectChange}
                                    placeholder="Add products to collection..."
                                    />
                                    <InputGroupAddon addonType="append">
                                        <span className="input-group-text py-0 px-1" >
                                            <Icon icon={arrowDownBold} width="25" height="25" />
                                        </span>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable className="d-flex" droppableId='products'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {collection.products?.map((product, i) => (
                                        <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                            <Card className="mb-0">
                                                <Row>
                                                    <Col className="d-flex">
                                                <div className="bg-light h-100 rounded-left" style={{ width: '60px' }} {...provided.dragHandleProps}>
                                                    <Icon icon={dragIcon} width="60" height="80" />
                                                </div>
                                                <div className="h-100" style={{ width: '80px', overflow: 'hidden', minWidth: "80px" }}>
                                                    <img 
                                                    src="http://dummyimage.com/80"
                                                    // src={getFS1Image(product.prodImage.file, 80)}
                                                    ></img>
                                                </div>
                                                <div 
                                                style={{ flex: 1, overflow: 'hidden', maxHeight: '80px' }}
                                                className="h-100 ml-3 d-flex flex-column justify-content-around">
                                                    <div style={{ overflow: 'hidden' }}><strong>{truncateString(product.prodName, 100)}</strong></div>
                                                    {/* <div>
                                                    {product.inventoryCount > 0 ?
                                                    <><Icon icon={circleSlice8} color="#03D762" /><small>In Stock</small></>
                                                    :
                                                    <><Icon icon={circleSlice8} color="#E70435" /><small>Out of Stock</small></>}
                                                    </div> */}
                                                </div>
                                                <div className="btn-danger btn-icon delete-btn-icon position-absolute rounded-circle d-flex justify-content-center align-items-center" 
                                                style={{ height: '25px', width: '25px', right: '3px', top: '-7px' }}
                                                onClick={() => removeCollectionProduct(product.prodToken)}
                                                >
                                                    <Icon icon={closeIcon} width="15" height="15" color="white" className="delete-btn-icon" />
                                                </div>
                                                {/* <div className="bg-light h-100 rounded-right ml-auto" style={{ width: '60px' }}>
                                                    <Icon icon={trashCanOutline} width="60" height="80" />
                                                </div> */}
                                                {/* <div className="h-100 ml-3 d-flex flex-column justify-content-around">
                                                    <div><strong>{product.prodName}</strong></div>
                                                    <div>3 variants <br />
                                                    Brand
                                                    </div>
                                                </div> */}
                                                {/* <div className="h-100 ml-auto mr-3 d-flex align-items-center">
                                                {product.inventoryCount > 0 ?
                                                    <><Icon icon={circleSlice8} color="#03D762" /><small>In Stock</small></>
                                                    :
                                                    <><Icon icon={circleSlice8} color="#E70435" /><small>Out of Stock</small></>}
                                                </div> */}
                                                </Col>
                                                </Row>
                                            </Card>
                                            </div>)}</Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <div className="button-list d-flex justify-content-end">
                        <Button color="danger" size="lg" disabled={!collection.collectionToken}
                        onClick={deleteCollection}
                        >Delete</Button>
                        <Button color="primary" size="lg" className="ml-3" onClick={saveCollection}>Save</Button>
                    </div>
                </Col>
            </Row>
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading collection...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
        )
}

export default Collection;