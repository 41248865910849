import { FS1STATICURL, FS1URL } from "../constants";

export const commonHelper = {
    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    async filterData(obj, type, value, name = '') {
        if (type === 'limit') {
            const res = obj?.filter((el, index) => {
                if (index < value) {
                    console.log('test')
                }
                return index < value;
            });
            console.log(res);
            return res;
        }
    },
    async goOnToOfThePage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return true
    },
    solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery, visibilityFilter = undefined) {
        let startIndex = (page - 1) * sizePerPage;

        const getQueryForBackend = (query) => {
            return `catchAll:*${query
                .split(' ')
                .map((e) =>
                    e
                        .replace(/^[^A-Za-z0-9]|[^A-Za-z0-9]$/g, '')
                        .replace(/^\.|\.$/g, '')
                        .replace(/[^A-Za-z0-9 \.]/g, ' ')
                        .trim()
                        .replace(/ +/g, ' ')
                )
                .filter((e) => e !== '')
                .join('* *')}*~2`;
        };
        console.log(`/solr?start=${startIndex}${sortField ? '&sort=' + sortField : ''}&rows=${sizePerPage}&sortOrder=${sortOrder}${visibilityFilter ? `&fq=${visibilityFilter}` : ''}${searchQuery ? '&q=' + (searchQuery) : ''}`)
        //.replace(/^\.|\.$/g, '').replace(/[^A-Za-z0-9]/g, ' ')
        return `/solr?start=${startIndex}${sortField ? '&sort=' + sortField : ''}&rows=${sizePerPage}&sortOrder=${sortOrder}${visibilityFilter ? `&fq=${visibilityFilter}` : ''}${searchQuery ? '&q=' + (searchQuery).replace(/(#)/g, '%23') : ''
            }`;
    },
     solrUrlParamsUpdated(page, sizePerPage, sortOrder, sortField, searchQuery, visibilityFilter, archive) {
        let startIndex = (page - 1) * sizePerPage;
        let url = `/solr?start=${startIndex}&rows=${sizePerPage}`;
    
        // Append sorting parameters if provided
        if (sortField && sortOrder) {
            url += `&sort=${sortField}&sortOrder=${sortOrder}`;
        } else if (sortField) {  // If only sortField is provided without sortOrder
            url += `&sort=${sortField}`;
        }
        console.log('searchQuery',searchQuery);
        // Append the search query if provided
        // if (searchQuery) {
        //     // searchQuery = searchQuery.replace(/(#)/g, '%23'); // Manually replace '#' for URL usage
        //     url += `&q=${searchQuery}`;
        // }
    
        // Add the visibility filter only if it's provided and not an empty string
        if (searchQuery && searchQuery !== '') {
            url += `&${searchQuery}`;
        }
        console.log('archive', archive);
        // Append 'archived' as a separate parameter if it is explicitly set to 1
        if (archive === '1') { // Make sure archived is treated as a string comparison
            url += `&archive=1`;
        }
    
        // Log the constructed URL for debugging
        console.log('Constructed Solr query:', url);
    
        return url;
    }
    
    ,
       
    returnFs1StaticImagesUrl(token = '', size = null, isFolder = false, img = '') {
        if (isFolder) {
            return FS1URL+`/${token}/${img}/${size}`;//return `https://fs1.cloudsnob.com/static/${token}/${img}/${size}`
        } else {
           if (size !== null) {
                return FS1URL + token + "/" + size;
            } else {
                return FS1URL + token;
            }

        }
    },
    returnDummyImagesUrl(size = 50) {
        return "http://dummyimage.com/" + size;
    },
    buildQueryParams(page, sizePerPage, sortOrder, sortField, searchQuery) {
        return `?per_page=${sizePerPage}&current_page=${page}&sort_by=${sortField}&order_by=${sortOrder}&keyword=${(searchQuery) ? searchQuery : ''}`;
    },
    isEmptyString(str) {
        return (!str || str.length === 0);
    },
    calCulateTotal(subTotal = 0, shiping = 0, discount = {}, tax = 0) {
        var discountVal = (discount?.value) ? parseFloat(discount?.value) : 0;
        if (discount?.type?.label === 'Percentage') {
            discountVal = parseFloat(discount.value) * parseFloat(subTotal) / 100;
        }
        return (subTotal + shiping + tax - (discountVal)).toFixed(2);
    },
    calCulateDiscountAmount(discount = {}, subTotal) {
        var discountVal = (discount?.value) ? parseFloat(discount?.value) : 0;
        var totalAmount = 0
        if (discount?.type?.label === 'Percentage') {
            discountVal = parseFloat(discount.value) + " ";
            totalAmount = parseFloat(discount.value) * parseFloat(subTotal) / 100;
        } else {
            discountVal = parseFloat(discount.value);
        }
        return discount?.type?.label === 'Percentage' ? discountVal + " ( $" + totalAmount.toFixed(2) + " )" : commonHelper.returnWithCurrency(discountVal.toFixed(2));
    },
    returnWithCurrency(amount, needToFexed = true) {
        if (needToFexed) {
            return '$' + amount
        } else {
            return '$' + amount
        }
    },
    returnDiscountType(type = '') {
        return (type === 'Amount') ? '$' : '%'
    },
    returnCartCollectionOfProduct(item, type) {
        if (type === 'product') {
            return {
                prodImage: item?.prodImage,
                prodToken: item?.prodToken,
                brandName: item?.brandName,
                price: item?.variantPrice,
                variantToken: item?.variantToken,
                variantOptions: item?.variantOptions,
                spec: item?.specs,
                variantName: item?.variantName,
                prodName: item?.prodName,
            }
        } else {
            return {
                prodImage: item?.prodImage,
                prodToken: item?.prodToken,
                brandName: item?.brandName,
                price: item?.variantPrice,
                variantToken: item?.variantToken,
                variantOptions: item?.variantOptions,
                spec: item?.specs,
                variantName: item?.variantName,
                prodName: item?.prodName,
            }
        }
    },
    removeItemFromCart(arrObjCart, variantToken) {
        if (arrObjCart.length > 0) {
            const objReturnData = [];
            arrObjCart.map((item) => {
                if (item.variantToken !== variantToken) {
                    objReturnData.push(item);
                }
            });
            //console.log(objReturnData);
            return objReturnData;
        }
    },
    checkItemExistsInCart(arrObjCart, variantToken) {
        if (arrObjCart.length > 0) {
            let objReturnData = false;
            arrObjCart.map((item) => {
                if (item.variantToken === variantToken) {
                    return objReturnData = true;
                }
            });
            return objReturnData;
        }
    },
    checkProductExistsInCart(arrObjCart, prodToken) {
        if (arrObjCart.length > 0) {
            let objReturnData = false;
            arrObjCart.map((item) => {
                if (item.prodToken === prodToken) {
                    return objReturnData = true;
                }
            });
            return objReturnData;
        }
    },
    calculateCartSubtotal(arrObjCart) {
        if (arrObjCart.length > 0) {
            let objReturnData = 0;
            arrObjCart.map((item) => {
                objReturnData = objReturnData + (item.price * item.qyt);
            });
            return objReturnData;
        } else {
            return 0;
        }
    },
    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },
    updateItemFromCart(arrObjCart, variantToken, qyt) {
        if (arrObjCart.length > 0) {
            const objReturnData = [];
            arrObjCart.map((item) => {
                if (item.variantToken === variantToken) {
                    item.qyt = parseInt(qyt)
                }
                objReturnData.push(item);
            });
            //console.log(objReturnData);
            return objReturnData;
        }
    },
    checkCreditCard(cardnumber) {
        //Error messages
        console.log(cardnumber);
        const ccErrors = [];
        ccErrors[0] = "Unknown card type";
        ccErrors[1] = "No card number provided";
        ccErrors[2] = "Credit card number is in invalid format";
        ccErrors[3] = "Credit card number is invalid";
        ccErrors[4] = "Credit card number has an inappropriate number of digits";
        ccErrors[5] = "Warning! This credit card number is associated with a scam attempt";
        //Response format
        const response = (success, message = null, type = null) => ({
            message,
            success,
            type
        });
        // Define the cards we support. You may add additional card types as follows.
        //  Name:         As in the selection box of the form - must be same as user's
        //  Length:       List of possible valid lengths of the card number for the card
        //  prefixes:     List of possible prefixes for the card
        //  checkdigit:   Boolean to say whether there is a check digit
        const cards = [];
        cards[0] = {
            name: "Visa",
            length: "13,16",
            prefixes: "4",
            checkdigit: true
        };
        cards[1] = {
            name: "MasterCard",
            length: "16",
            prefixes: "51,52,53,54,55",
            checkdigit: true
        };
        cards[2] = {
            name: "DinersClub",
            length: "14,16",
            prefixes: "36,38,54,55",
            checkdigit: true
        };
        cards[3] = {
            name: "CarteBlanche",
            length: "14",
            prefixes: "300,301,302,303,304,305",
            checkdigit: true
        };
        cards[4] = {
            name: "AmEx",
            length: "15",
            prefixes: "34,37",
            checkdigit: true
        };
        cards[5] = {
            name: "Discover",
            length: "16",
            prefixes: "6011,622,64,65",
            checkdigit: true
        };
        cards[6] = {
            name: "JCB",
            length: "16",
            prefixes: "35",
            checkdigit: true
        };
        cards[7] = {
            name: "enRoute",
            length: "15",
            prefixes: "2014,2149",
            checkdigit: true
        };
        cards[8] = {
            name: "Solo",
            length: "16,18,19",
            prefixes: "6334,6767",
            checkdigit: true
        };
        cards[9] = {
            name: "Switch",
            length: "16,18,19",
            prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
            checkdigit: true
        };
        cards[10] = {
            name: "Maestro",
            length: "12,13,14,15,16,18,19",
            prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
            checkdigit: true
        };
        cards[11] = {
            name: "VisaElectron",
            length: "16",
            prefixes: "4026,417500,4508,4844,4913,4917",
            checkdigit: true
        };
        cards[12] = {
            name: "LaserCard",
            length: "16,17,18,19",
            prefixes: "6304,6706,6771,6709",
            checkdigit: true
        };
        // Ensure that the user has provided a credit card number
        if (cardnumber.length == 0) {
            return response(false, ccErrors[1]);
        }
        // Now remove any spaces from the credit card number
        // Update this if there are any other special characters like -
        cardnumber = cardnumber.replace(/\s/g, "");
        // Validate the format of the credit card
        // luhn's algorithm
        if (!this.validateCardNumber(cardnumber)) {
            return response(false, ccErrors[2]);
        }
        // Check it's not a spam number
        if (cardnumber == '5490997771092064') {
            return response(false, ccErrors[5]);
        }
        // The following are the card-specific checks we undertake.
        let lengthValid = false;
        let prefixValid = false;
        let cardCompany = "";
        // Check if card belongs to any organization
        for (let i = 0; i < cards.length; i++) {
            const prefix = cards[i].prefixes.split(",");
            for (let j = 0; j < prefix.length; j++) {
                const exp = new RegExp("^" + prefix[j]);
                if (exp.test(cardnumber)) {
                    prefixValid = true;
                }
            }
            if (prefixValid) {
                const lengths = cards[i].length.split(",");
                // Now see if its of valid length;
                for (let j = 0; j < lengths.length; j++) {
                    if (cardnumber.length == lengths[j]) {
                        lengthValid = true;
                    }
                }
            }
            if (lengthValid && prefixValid) {
                cardCompany = cards[i].name;
                return response(true, null, cardCompany);
            }
        }
        // If it isn't a valid prefix there's no point at looking at the length
        if (!prefixValid) {
            return response(false, ccErrors[3]);
        }
        // See if all is OK by seeing if the length was valid
        if (!lengthValid) {
            return response(false, ccErrors[4]);
        };
        // The credit card is in the required format.
        return response(true, null, cardCompany);
    },
    validateCardNumber(number) {
        //Check if the number contains only numeric value  
        //and is of between 13 to 19 digits
        const regex = new RegExp("^[0-9]{13,19}$");
        if (!regex.test(number)) {
            return false;
        }

        return this.luhnCheck(number);
    },
    luhnCheck(val) {
        let checksum = 0; // running checksum total
        let j = 1; // takes value of 1 or 2
        // Process each digit one by one starting from the last
        for (let i = val.length - 1; i >= 0; i--) {
            let calc = 0;
            // Extract the next digit and multiply by 1 or 2 on alternative digits.
            calc = Number(val.charAt(i)) * j;
            // If the result is in two digits add 1 to the checksum total
            if (calc > 9) {
                checksum = checksum + 1;
                calc = calc - 10;
            }
            // Add the units element to the checksum total
            checksum = checksum + calc;
            // Switch the value of j
            if (j == 1) {
                j = 2;
            } else {
                j = 1;
            }
        }
        //Check if it is divisible by 10 or not.
        return (checksum % 10) == 0;
    },
    creditCardType(cc) {
        let amex = new RegExp('^3[47][0-9]{13}$');
        let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
        let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

        let mastercard = new RegExp('^5[1-5][0-9]{14}$');
        let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

        let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
        let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
        let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

        let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
        let jcb = new RegExp('^35[0-9]{14}[0-9]*$');


        if (visa.test(cc)) {
            return 'VISA';
        }
        if (amex.test(cc)) {
            return 'AMEX';
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
            return 'MASTERCARD';
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
            return 'DISCOVER';
        }
        if (diners.test(cc)) {
            return 'DINERS';
        }
        if (jcb.test(cc)) {
            return 'JCB';
        }
        if (cup1.test(cc) || cup2.test(cc)) {
            return 'CHINA_UNION_PAY';
        }
        return undefined;
    },
    getCreditCardType(accountNumber) {
        //start without knowing the credit card type
        var result = "unknown";
        //first check for MasterCard
        if (/^5[1-5]/.test(accountNumber)) {
            result = {name:"MASTER",img:'/images/payments/master.png'};
        }
        //then check for Visa
        else if (/^4/.test(accountNumber)) {
            result = {name:"VISA",img:'/images/payments/visa.png'};
        }
        //then check for AmEx
        else if (/^3[47]/.test(accountNumber)) {
            result = {name:"AMEX",img:'/images/payments/master.png'};
        }

        else if (/^6[0-4]/.test(accountNumber)) {
            result = {name:"DISCOVER",img:'/images/payments/discover.png'};
        }
        else if (/^3[0689]/.test(accountNumber)) {
            result = {name:"DINERS",img:'/images/payments/diners.png'};
        }
        else if (/^35[0-9]/.test(accountNumber)) {
            result = {name:"JCB",img:''};
        }
        return result;
    },
    returnFs1ImagesUrl(params) {
        return FS1URL+"/" + params;
    },
    addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }
}