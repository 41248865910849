
import React, { useEffect, useState, useContext } from 'react';
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';
import { Button, Card, Col, FormGroup, FormText, Input, Label, Row, CustomInput, UncontrolledTooltip, CardHeader } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { useHistory } from 'react-router-dom';
import { CommonApiRequest } from '../services/commonApiRequest';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CountryList, USStates } from '../data/CountriesList';
import informationOutline from '@iconify/icons-mdi/information-outline';
import { Icon } from '@iconify/react';

const NewCustomer = props => {

     // Define the default country and state
     const defaultCountry = CountryList.find(country => country.code === "US");
     const defaultState = defaultCountry ? [defaultCountry] : null;
 
    const [isDisable, setIsDisable] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    // Set initial state with default values
    const [selectedCountry, setSelectedCountry] = useState(defaultState);
    const [selectedState, setSelectedState] = useState([]);

    const [checkboxes, setCheckboxes] = useState({
        marketingEmail: false,
        marketingSMS: false,
        collectTax: false,
        notes: false
    });

    const history = useHistory();
    const [validateState, setValidateState] = useState({
        fname: false
    });
    const [newCustomer, setNewCustomer] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        company: '',
        city: '',
        apartmet:'',
       
        address: '',
        zipcode:''
       
    });
    const resetForm = () => {
        setNewCustomer({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            company: '',
            city: '',
            apartment:'',
            address: '',
            zipcode:''
        });
    }
    const saveNewCustomer = () => {
        setValidateState({ fname: false, lname: false })
        if (newCustomer?.fname !== "" && newCustomer?.lname !== "") {
            if (isEmailValid) {
                let customerSavingToast = toast.loading('Saving customer...', {
                    theme: 'light',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsDisable(true);
                CommonApiRequest.createNewCustomer({
                    customerFirstName: newCustomer?.fname,
                    customerLastName: newCustomer?.lname,
                    customerEmail: newCustomer?.email,
                    customerPhone: newCustomer?.phone,
                    customerCompanyName: newCustomer?.company,
                    address: newCustomer?.address,
                    address2: newCustomer?.apartment,
                    addressCity: newCustomer?.city,
                    addressState: selectedState?.selectedState,
                    addressZip: newCustomer?.zipcode,
                    phoneNumber: newCustomer?.phone,
                    addressCountry: selectedCountry?.selectedCountry,
                    
                    customermarketingEmail: checkboxes?.marketingEmail,
                    customermarketingSMS: checkboxes?.marketingSMS,
                    taxExempt: checkboxes?.collectTax,
                    customerNotes: checkboxes?.notes,  

                }).then((response) => {
                    if (response?.customerToken) {
                        toast.update(customerSavingToast,
                            {
                                render: "Customer created successfully!",
                                type: "success",
                                isLoading: false,
                                autoClose: null,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                        history.push("/omni/customer/" + response?.customerToken);
                    }
                }).catch(() => {
                    toast.error("Please fill the required field");
                })
            }
        } else {
            const validationStatus = {};
            if (newCustomer?.fname === "") {
                validationStatus.fname = true
            }
            if (newCustomer?.lname === "") {
                validationStatus.lname = true
            }
            setValidateState(validationStatus);
        }

    }
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setNewCustomer({ ...newCustomer, [name]: target?.value });
    }

    // Function to handle country change
    const handleCountryChange = (selected) => {
        console.log("Selected country: ", selected);

        if (selected.length > 0) {
            const countryCode = selected[0].code;

            if (countryCode !== 'US') {
                setSelectedState([]); // Clear state if country is not US
            }
        }

        setSelectedCountry(selected); // Update selected country state
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
    };
    console.log('selected Country', selectedCountry);

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Customer | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Customers', path: '/omni/customers' },
                    { label: 'New Customer', path: props.location.pathname, active: true },
                ]}
                title={"New Customer"}
            />
            <Card className='p-3'>
                <form>
                    <h4 className="mb-2">Overview</h4>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="fname">First Name <span className='required-field'>*</span></Label>
                                <Input name="fname" id="fname" value={newCustomer?.fname}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                                {validateState.fname && <div className="invalid-text">Customer first name required</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="lname">Last Name <span className='required-field'>*</span></Label>
                                <Input name="lname" id="lname" value={newCustomer?.lname}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                                {validateState.lname && <div className="invalid-text">Customer last name required</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input name="email" id="email" value={newCustomer?.email}
                                    onChange={(e) => handleInputChange(e)}
                                    onBlur={(e) => {
                                        var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                                        if (e.target.value !== "") {
                                            setIsEmailValid(regex.test(e.target.value));
                                        } else {
                                            setIsEmailValid(true);
                                        }
                                    }}
                                    placeholder=""
                                />
                                {!isEmailValid && <div className="invalid-text">Customer valid email address</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <MaskedInput
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    name="phone"
                                    id="phone"
                                    value={newCustomer.phone}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                    className="form-control"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={7} sm={12} className='d-flex align-items-center'>
                            <FormGroup className='d-flex w-50'>
                                <CustomInput
                                    type="checkbox"
                                    name="marketingEmail"
                                    id="marketingEmail"
                                    label="Receive Marketing Emails"
                                    checked={checkboxes.marketingEmail}
                                    onChange={handleCheckboxChange}
                                />
                                <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="cart-quantity-combine" />
                                <UncontrolledTooltip placement="top" target="cart-quantity-combine">
                                    By checking this box, you agree to receive promotional emails and updates from us.                                    </UncontrolledTooltip>
                            </FormGroup>
                            <FormGroup className='d-flex w-50 justify-content-center'>
                                <CustomInput
                                    type="checkbox"
                                    name="marketingSMS"
                                    id="marketingSMS"
                                    label="Receive Marketing Text Messages"
                                    checked={checkboxes.marketingSMS}
                                    onChange={handleCheckboxChange}
                                />
                                <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="quotes-enabled" />
                                <UncontrolledTooltip placement="top" target="quotes-enabled">
                                    By checking this box, you agree to receive promotional text messages and updates from us.                                    </UncontrolledTooltip>
                            </FormGroup>
                        </Col>
                    </Row>
                    <h4 className="mb-2">Address</h4>
                    <p className="mb-2">Default Address</p>
                    <Row>
                        
                        {/* <Col sm={12} md={6} lg={4}>
                <FormGroup>
                    <Label for="lname">Country/Region <span className='required-field'>*</span></Label>
                    <Typeahead
                                // inputProps={{
                                //     // className: `${!validState?.selectedLocations ? 'invalid' : ''}`
                                // }}
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="name"
                                multiple
                                options={CountryList.map(country => country)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                onChange={handleCountryChange}
                                // selected={(coupon.locationsEligible) ?
                                // coupon.locationsEligible.map(locCode => CountryList.find(cntry => cntry.code === locCode))
                                // .filter(loc => loc !== undefined)
                                // :
                                // []
                                // }
                                placeholder="Select country..."
                                // disabled={coupon.locationsEligibleType === 'all'}
                                isValidNewOption={() => false} // Disable creation of new options

                                />
                    {validateState.lname && <div className="invalid-text">Customer last name required</div>}
                </FormGroup>
            </Col> */}
                        
                       
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="fname">First Name</Label>
                                <Input name="fname" id="fname" value={newCustomer?.fname}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                                {validateState.fname && <div className="invalid-text">Customer first name required</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="lname">Last Name</Label>
                                <Input name="lname" id="lname" value={newCustomer?.lname}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                                {validateState.lname && <div className="invalid-text">Customer last name required</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="company">Company</Label>
                                <Input name="company" id="company" value={newCustomer?.company}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="countrySelect">Country/Region <span className='required-field'>*</span></Label>
                                <Typeahead
                                    id="countrySelect"
                                    labelKey="name"
                                    options={CountryList.map(country => country)}
                                    onChange={handleCountryChange}
                                    selected={selectedCountry}
                                    placeholder="Select country..."
                                />
                            </FormGroup>
                        </Col>
                        {selectedCountry?.length > 0 && selectedCountry[0]?.code === 'US' && (
                            <Col sm={12} md={6} lg={4}>
                                <FormGroup>
                                    <Label for="stateSelect">State</Label>
                                    <Typeahead
                                        id="stateSelect"
                                        labelKey="name"
                                        options={USStates}
                                        onChange={setSelectedState}
                                        selected={selectedState}
                                        placeholder="Select state..."
                                        isValidNewOption={() => false} // Disable creation of new options
                                    />
                                    {/* Add validation if needed */}
                                </FormGroup>
                            </Col>
                        )}
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="city">City</Label>
                                <Input name="city" id="city" value={newCustomer?.city}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="apartment">Apartment, suite</Label>
                                <Input name="apartment" id="apartment" value={newCustomer?.apartment}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <Input name="address" id="address" value={newCustomer?.address}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                       
                        
                        
                       
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="zipcode">{selectedCountry[0]?.code === 'US' ? 'ZIP' : 'Post Code'}</Label>
                                <Input
                                    name="zipcode"
                                    id="zipcode"
                                    value={newCustomer?.zipcode}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <MaskedInput
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    name="phone"
                                    id="phone"
                                    value={newCustomer.phone}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder=""
                                    className="form-control"
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col sm={12} md={6} lg={4}>
                <FormGroup>
                    <Label for="lname">State<span className='required-field'>*</span></Label>
                    <Typeahead
                                // inputProps={{
                                //     // className: `${!validState?.selectedLocations ? 'invalid' : ''}`
                                // }}
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="name"
                                multiple
                                options={CountryList.map(country => country)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                onChange={onCountrySelectChange}
                                // selected={(coupon.locationsEligible) ?
                                // coupon.locationsEligible.map(locCode => CountryList.find(cntry => cntry.code === locCode))
                                // .filter(loc => loc !== undefined)
                                // :
                                // []
                                // }
                                placeholder="Select state..."
                                // disabled={coupon.locationsEligibleType === 'all'}
                                />
                    {validateState.lname && <div className="invalid-text">state required</div>}
                </FormGroup>
            </Col> */}

                    </Row>
                    <h4 className="mb-4">Tax Exmpetions</h4>
                    <Col md={7} sm={12} className='d-flex align-items-center'>
                        <FormGroup className='d-flex w-50'>
                            <CustomInput
                                type="checkbox"
                                name="collectTax"
                                id="collectTax"
                                label="Collect tax"
                                checked={checkboxes.collectTax}
                                onChange={handleCheckboxChange}
                            />
                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="cart-quantity-combine" />
                            <UncontrolledTooltip placement="top" target="cart-quantity-combine">
                                By checking this box, you agree to receive promotional emails and updates from us.                                    </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup className='d-flex w-50 justify-content-center'>
                            <CustomInput
                                type="checkbox"
                                name="notes"
                                id="notes"
                                label="Notes"
                                checked={checkboxes.notes}
                                onChange={handleCheckboxChange}
                            />
                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="quotes-enabled" />
                            <UncontrolledTooltip placement="top" target="quotes-enabled">
                                By checking this box, you agree to receive promotional text messages and updates from us.                                    </UncontrolledTooltip>
                        </FormGroup>
                    </Col>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="dark"
                                type="reset"
                                onClick={() => resetForm()}
                                className="export-csv-btn btn btn-primary mr-3"
                                disabled={isDisable}>
                                Reset
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => saveNewCustomer()}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card>

        </>
    )
}
export default NewCustomer;