import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Badge,
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { select } from '@redux-saga/core/effects';
import { OmniApi } from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import moment from 'moment';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CommonApiRequest } from '../services/commonApiRequest';
import RemotePaginations from '../components/Datatables/RemotePagination';
import { commonHelper } from '../helpers/commonHelper';

// const RemotePagination = ({
//     data,
//     columns,
//     page,
//     sizePerPage,
//     onTableChange,
//     totalSize,
//     showTotal = true,
//     paginationTotalRenderer,
// }) => {
//     const { ExportCSVButton } = CSVExport;

//     const location = useLocation();
//     const query = new URLSearchParams(location.search);
//     const history = useHistory();

//     // The onCLick in the row event routes the user to the brand detail page when row is clicked on
//     const rowEvents = {
//         onClick: (e, row) => {
//             const location = {
//                 pathname: `/omni/catalog/order/${row.orderToken}`,
//                 state: {
//                     row,
//                 },
//             };

//             history.push(location);
//         },
//     };

//     const rowClasses = (row, rowIndex) => {
//         return 'table-row';
//     };

//     const [searchTerm, setSearchTerm] = useState('helo');

//     const searchChange = (e) => {
//         let result = e.target.value;
//         let page = parseInt(query.get('page'));
//         if (page > 1) query.set('page', '1');
//         query.set('searchTerm', result);
//         history.push(location.pathname + '?' + query.toString());
//     };

//     useEffect(() => {
//         let result = query.get('searchTerm');
//         setSearchTerm(result);
//     }, [location.search]);

//     const [selectedRows, setSelectedRows] = useState([]);

//     const selectRow = {
//         mode: 'checkbox',
//         style: { background: '#CCD0FB' },
//         clickToSelect: false,
//         onSelectAll: (isSelect, rows, e) => {
//             if (isSelect) {
//                 setSelectedRows(rows.map((e) => e.prodToken));
//             } else {
//                 setSelectedRows([]);
//             }
//         },
//         onSelect: (row, isSelect, rowIndex, e) => {
//             // console.log(row, isSelect, rowIndex);
//             if (isSelect) {
//                 setSelectedRows([...selectedRows, row.prodToken]);
//             } else {
//                 setSelectedRows(selectedRows.filter((e) => e !== row.prodToken));
//             }
//         },
//         selectionRenderer: ({ checked, disabled }) => {
//             return (
//                 <div className="custom-control custom-checkbox">
//                     <input
//                         type="checkbox"
//                         className="custom-control-input row-checkbox"
//                         checked={checked}
//                         disabled={disabled}
//                         onChange={() => {}}
//                     />
//                     <label
//                         className="custom-control-label row-checkbox-label"
//                         onClick={(e) => {
//                             e.preventDefault();
//                         }}></label>
//                 </div>
//             );
//         },
//         selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
//             return (
//                 <div className="custom-control custom-checkbox select-header">
//                     <input
//                         type="checkbox"
//                         className="custom-control-input"
//                         id="customCheck1"
//                         ref={(input) => {
//                             if (input) input.indeterminate = indeterminate;
//                         }}
//                         {...rest}
//                         onChange={() => {}}
//                     />
//                     <label
//                         className="custom-control-label"
//                         htmlFor="customCheck1"
//                         onClick={(e) => {
//                             e.preventDefault();
//                         }}></label>
//                 </div>
//             );
//         },
//     };

//     return (
//         <ToolkitProvider
//             bootstrap4
//             keyField="orderToken"
//             data={data}
//             columns={columns}
//             exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
//             {(props) => (
//                 <React.Fragment>
//                     <Row className="mb-2">
//                         <Col className="text-right form-inline justify-content-end">
//                             <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
//                                 Export CSV
//                             </ExportCSVButton>
//                             <input
//                                 className="form-control"
//                                 placeholder="Search orders"
//                                 value={searchTerm || ''}
//                                 onChange={(e) => searchChange(e)}
//                             />
//                         </Col>
//                     </Row>

//                     <BootstrapTable
//                         {...props.baseProps}
//                         remote
//                         bordered={false}
//                         headerClasses="thead-light"
//                         // defaultSorted={ defaultSorted }
//                         pagination={paginationFactory({
//                             page,
//                             sizePerPage,
//                             totalSize,
//                             paginationTotalRenderer,
//                             showTotal,
//                         })}
//                         wrapperClasses="table-responsive"
//                         classes="order-table"
//                         bordered={false}
//                         rowEvents={rowEvents}
//                         // selectRow={ selectRow }
//                         rowClasses={rowClasses}
//                         hover
//                         onTableChange={onTableChange}
//                         // noDataIndication={() => 'No results...'}
//                     />
//                 </React.Fragment>
//             )}
//         </ToolkitProvider>
//     );
// };

const Orders = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [products, setProducts] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: null,
        data: [],
        sizePerPage: null,
        sortOrder: null,
        sortField: null,
        totalSize: null,
    });

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;

    //     setPaginationValues({
    //         ...paginationValues,
    //         data: products ? products.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     })
    // }, [products])

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: 'orderNumber',
            text: 'No.',
            sort: true,
            style: { verticalAlign: 'middle' },
        },
        {
            dataField: 'contact_lastname_str',
            text: 'Name',
            sort: true,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => `${row.contactFirstName}`,
        },
        {
            dataField: 'dateCreated',
            text: 'Date',
            sort: true,
            formatter: (cell) => {
                let date = epochToLocal(cell);
                return moment(date).format('lll');
            },
            style: { verticalAlign: 'middle' },
        },
        {
            dataField: 'orderStatus',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return (
                    <Badge
                        style={{ backgroundColor: row.orderStatusValue?.color + 95, color: '#121212' }}
                        pill
                        className="mr-1">
                        {row.orderStatusValue?.name}
                    </Badge>
                );
            },
            style: { verticalAlign: 'middle' },
        },
        {
            dataField: 'orderProdQuantity',
            text: 'Quantity',
            sort: true,
            style: { verticalAlign: 'middle' },
        },
        {
            dataField: 'checkoutObject',
            text: 'Coupon',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell) => {
                if (cell?.couponCodes?.length) return cell?.couponCodes[0].toUpperCase();
                else return '';
            },
        },
        {
            dataField: 'PO',
            text: 'PO #',
            sort: false,
            style: { verticalAlign: 'middle' },
        },
        {
            dataField: 'orderTotal',
            text: 'Total',
            sort: true,
            style: { verticalAlign: 'middle' },
            formatter: (cell) => `$${parseFloat(cell).toFixed(2)}`,
        },
    ];

    const epochToLocal = (epoch) => {
        var d = new Date(0);
        return d.setUTCSeconds(epoch);
    };

    // const selectRow = {
    //     mode: 'checkbox',
    //     style: { background: '#5bc0de' },
    //     clickToSelect: false,
    //     onSelectAll: (isSelect, rows, e) => {
    //         if (isSelect){
    //             setSelectedRows(rows.map(e => e.brandToken))
    //         } else {
    //             setSelectedRows([])
    //         }
    //       },
    //     onSelect: (row, isSelect, rowIndex, e) => {
    //         // console.log(row, isSelect, rowIndex);
    //         if (isSelect){
    //             setSelectedRows([...selectedRows, row.brandToken])
    //         } else {
    //             // this.setState({ selectedRows: this.state.selectedRows.filter(e => e !== row.brandToken) });
    //             setSelectedRows(selectedRows.filter(e => e !== row.brandToken))
    //         }
    //       },
    //     selectionRenderer: ({ checked, disabled }) => {
    //         return (
    //             <div className="custom-control custom-checkbox">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input row-checkbox"
    //                     checked={checked}
    //                     disabled={disabled}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label row-checkbox-label"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    //     selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
    //         return (
    //             <div className="custom-control custom-checkbox select-header">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input"
    //                     id="customCheck1"
    //                     ref={input => {
    //                         if (input) input.indeterminate = indeterminate;
    //                     }}
    //                     {...rest}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label"
    //                     htmlFor="customCheck1"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    // };

    // function getFS1Image(prodImage){
    //     return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${prodImage}/50`
    // }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search) {
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', '');
            query.set('searchTerm', '');
            history.replace(location.pathname + '?' + query.toString());
        }

        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchTerm');
        const solrParams = commonHelper.solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery);
        CommonApiRequest.getOrderDetail(`${solrParams}`).then(
            (res) => {
                commonHelper.goOnToOfThePage();
                let orders = res.docs;
                orders.map((order) => {
                    if (order.orderStatus === 'os_awaiting_fulfillment') {
                        order.orderStatusValue = {
                            name: 'Awaiting Fulfillment',
                            color: '#ffc107',
                        };
                    } else if (order.orderStatus === 'os_processing') {
                        order.orderStatusValue = {
                            name: 'Processing',
                            color: '#cccccc',
                        };
                    } else if (order.orderStatus === 'os_shipped') {
                        order.orderStatusValue = {
                            name: 'Shipped',
                            color: '#30b807',
                        };
                    } else if (order.orderStatus === 'os_pickup_ready') {
                        order.orderStatusValue = {
                            name: 'Ready For Pickup',
                            color: '#30b807',
                        };
                    } else if (order.orderStatus === 'os_completed') {
                        order.orderStatusValue = {
                            name: 'Completed',
                            color: '#007bff',
                        };
                    } else if (order.orderStatus === 'os_refunded') {
                        order.orderStatusValue = {
                            name: 'Refunded',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'os_partial_refund') {
                        order.orderStatusValue = {
                            name: 'Partially Refunded',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'os_cancelled') {
                        order.orderStatusValue = {
                            name: 'Cancelled',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'os_voided') {
                        order.orderStatusValue = {
                            name: 'Voided Payment',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'ps_7') {
                        order.orderStatusValue = {
                            name: 'Fulfilled',
                            color: '#30b807',
                        };
                    } else if (order.orderStatus === 'Processing') {
                        order.orderStatusValue = {
                            name: 'Processing',
                            color: '#ffc107',
                        };
                    } else if (order.orderStatus === 'Cancelled By Admin') {
                        order.orderStatusValue = {
                            name: 'Cancelled By Admin',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'ps_3') {
                        order.orderStatusValue = {
                            name: 'In Review',
                            color: '#ffc107',
                        };
                    } else if (order.orderStatus === 'ps_2') {
                        order.orderStatusValue = {
                            name: 'Paused',
                            color: '#ffc107',
                        };
                    }
                    else if (order.orderStatus === 'os_2') {
                        order.orderStatusValue = {
                            name: 'Closed',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'ps_1') {
                        order.orderStatusValue = {
                            name: 'Processing',
                            color: '#ffc107',
                        };
                    } else if (order.orderStatus === 'ps_4') {
                        order.orderStatusValue = {
                            name: 'Cancelled By Customer',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'ps_5') {
                        order.orderStatusValue = {
                            name: 'Cancelled By Admin',
                            color: '#dc3545',
                        };
                    } else if (order.orderStatus === 'ps_6') {
                        order.orderStatusValue = {
                            name: 'Awaiting Fulfillment',
                            color: '#ffc107',
                        };
                    } else if (order.orderStatus === 'ps_8') {
                        order.orderStatusValue = {
                            name: 'Ready for Pickup',
                            color: '#30b807',
                        };
                    } else if (order.orderStatus === 'ps_9') {
                        order.orderStatusValue = {
                            name: 'Ready for Shipping',
                            color: '#30b807',
                        };
                    }
                });
                setPaginationValues({
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                    data: res.docs,
                    totalSize: res.numFound,
                });
            }
        );
        // OmniApi.get(`/orders${solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery)}`)
        // .then(res => {

        //     let orders = res.data.docs;
        //     orders.map((order) => {
        //         if(order.orderStatus === 'os_awaiting_fulfillment'){
        //             order.orderStatusValue = {
        //                 'name': 'Awaiting Fulfillment',
        //                 'color': '#ffc107'
        //             }
        //         } else if (order.orderStatus === 'os_processing'){
        //             order.orderStatusValue = {
        //                 "name":"Processing",
        //                 "color":"#cccccc"
        //             }
        //         } else if (order.orderStatus === 'os_shipped'){
        //             order.orderStatusValue = {
        //                 "name":"Shipped",
        //                 "color":"#30b807"
        //             }
        //         } else if (order.orderStatus === 'os_pickup_ready'){
        //             order.orderStatusValue = {
        //                 "name":"Ready For Pickup",
        //                 "color":"#30b807"
        //             }
        //         } else if (order.orderStatus === 'os_completed'){
        //             order.orderStatusValue = {
        //                 "name":"Completed",
        //                 "color":"#007bff"
        //             }
        //         } else if (order.orderStatus === 'os_refunded'){
        //             order.orderStatusValue = {
        //                 "name":"Refunded",
        //                 "color":"#dc3545"
        //             }
        //         } else if (order.orderStatus === 'os_partial_refund'){
        //             order.orderStatusValue = {
        //                 "name":"Partially Refunded",
        //                 "color":"#dc3545"
        //             }
        //         } else if (order.orderStatus === 'os_cancelled'){
        //             order.orderStatusValue = {
        //                 "name":"Cancelled",
        //                 "color":"#dc3545"
        //             }
        //         } else if (order.orderStatus === 'os_voided'){
        //             order.orderStatusValue = {
        //                 "name":"Voided Payment",
        //                 "color":"#dc3545"
        //             }
        //         }
        //     })

        //     setPaginationValues({
        //         page,
        //         sizePerPage,
        //         sortOrder,
        //         sortField,
        //         data: res.data.docs,
        //         totalSize: res.data.numFound
        //     })
        // })
    }, [location.search]);

    const solrUrlParams = (page, sizePerPage, sortOrder, sortField, searchQuery) => {
        let startIndex = (page - 1) * sizePerPage;

        const getQueryForBackend = (query) => {
            return `catchAll:"*${query
                .split(' ')
                .map((e) =>
                    e
                        .replace(/^[^A-Za-z0-9]|[^A-Za-z0-9]$/g, '')
                        .replace(/^\.|\.$/g, '')
                        .replace(/[^A-Za-z0-9 \.]/g, ' ')
                        .trim()
                        .replace(/ +/g, ' ')
                )
                .filter((e) => e !== '')
                .join('* *')}*"~2`;
        };

        return `/solr?start=${startIndex}${sortField ? '&sort=' + sortField : ''}${
            searchQuery ? '&q=' + getQueryForBackend(searchQuery) : ''
        }&rows=${sizePerPage}&sortOrder=${sortOrder}`.toString();
    };

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
        if (type === 'pagination') {
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort') {
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        console.log(paginationValues);
        history.push(location.pathname + '?' + query.toString());
    };

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if (user) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Orders | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <h2 className="page-title">Orders</h2>
                <Card className="mt-2">
                    <CardBody>
                        <RemotePaginations
                            data={data}
                            page={page}
                            columns={columns}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={onTableChange}
                            paginationTotalRenderer={customTotal}
                            showTotal={true}
                            rowClickPath={{path:'/omni/catalog/order',token:'orderToken'}}
                            // isNewButtonText="New Order"
                            // isNewButtonCallback={()=>{
                            //     history.push("/omni/order/draft/new");
                            // }}
                        />
                    </CardBody>
                </Card>
            </>
        );
    } else {
        return <h1>Loading</h1>;
    }
};

export default Orders;
